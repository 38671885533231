<template>
  <div v-if="!loading" class="widget">
    <!-- /Информация по платежам -->
    <ChartLayout
      title="Динамика продаж за год:"
      :needReload="needDateReload.payment"
    >
      <template v-slot:widget>
        <MixedCharts
          :labels="user_labels"
          :data="[
            {
              type: 'bar',
              label: 'Приложений',
              data: user_dates.registered,
              fill: true,
              borderColor: 'rgb(54, 133, 235)',
              backgroundColor: 'rgb(149,194,61)',
              order: 0
            },
            {
              type: 'bar',
              label: 'Карт',
              data: user_dates.unconfirmed,
              fill: true,
              borderColor: 'rgb(54, 162, 235)',
              backgroundColor: 'rgb(39,45,83)',
              order: 1
            }
          ]"
        />
      </template>
    </ChartLayout>
    <div style="display: none" class="">
      {{ this.$store.getters.getDealerWidgets }}
      {{ this.user_labels }}
    </div>

    <div style="display: none">
      <PieCharts />
    </div>

    <!-- /Информация по платежам -->
  </div>
</template>

<script>
import ChartLayout from "@/components/Charts/ChartLayout";
import PieCharts from "@/components/Charts/Pie";
import MixedCharts from "@/components/Charts/Mixed";
import moment from "moment";

export default {
  name: "Widgets",
  props: {
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      backgroundColor: [],
      diller: null,
      organization: null,
      needDateReload: {
        payment: false
      }
    };
  },
  components: {
    PieCharts,
    MixedCharts,
    ChartLayout
  },

  computed: {
    momentFormat() {
      return this.periodList.filter(el => el.value == this.dealer_period)[0]
        .moment;
    },
    user_count() {
      return [
        this.$store.getters.getUsersCount.total_registered,
        this.$store.getters.getUsersCount.total_unconfirmed
      ];
    },
    dealer_period: {
      get() {
        return this.$store.getters.getStatPeriodInfo.dealer;
      }
    },
    periodList() {
      return this.$store.getters.getStatPeriodListInfo;
    },
    user_labels() {
      let tempArr = this.$store.getters.getDealerWidgets.results?.map(el =>
        moment(el.period * 1000).format(this.momentFormat)
      );

      return tempArr || [];
    },
    user_dates() {
      return {
        registered:
          this.$store.getters.getDealerWidgets.results?.map(
            el => el.amount_app
          ) || [],
        unconfirmed:
          this.$store.getters.getDealerWidgets.results?.map(
            el => el.amount_card
          ) || []
      };
    }
  }
};
</script>

<style scoped></style>
